import React from 'react';
import Post from '../components/post';
import LazyLoadImage from '../components/LazyLoadImage';


const JimDaviesDebtPage = () => {
    const article = {
        id: '43c33646-e6fe-5313-bfd0-3a7411e46d35',
        title: 'How Jim Davies Paid off $400,000 in Debt in 21 Months',
        slug: '/jim-davies-debt/',
        date: '2018-03-08T17:55:32.000Z',
        modified: '2021-11-04T18:17:00.000Z',
        excerpt: 'Learn how to pay off a $400,000 debt in just 21 months. Refinance student loan debt and consolidate credit card debt to save interest. Apply the lessons learned from one person&#8217;s success to help solve your own debt puzzle.',
        featured_image: {
            source_url: '/media/doctor-wearing-white-coat.jpg',
        },
        acf: {
            seo_title: '',
            robots: 'index, follow',
        },
        read_time_minutes: 14,
        content: (
            <>
                <p>Being in debt isn&rsquo;t fun.</p>
                <p>No one enjoys it. It&rsquo;s stressful, and it can get overwhelming.</p>
                <p>
                    Even if you&rsquo;re just struggling with a $5,000
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=jim-davies-debt&amp;sub2=credit-card" rel="noopener noreferrer" target="_blank">credit card</a>
                    {' '}
                    debt, you still know what I&rsquo;m talking about.
                </p>
                <p>Now imagine having to deal with a debt 80 times bigger.</p>
                <p>Jim Davies paid off a massive $400,000 debt in just 21 months.</p>
                <p>
                    His formula for becoming debt-free was a combination of the right
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&amp;sub1=jim-davies-debt&amp;sub2=personal-loan" rel="noopener noreferrer" target="_blank">personal loan</a>
                    , strict budgeting, smart
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=jim-davies-debt&amp;sub2=student-loan" rel="noopener noreferrer" target="_blank">student loan</a>
                    {' '}
                    refinancing, and working really, really hard.
                </p>
                <p>I&rsquo;m a big fan of Jim and his gritty determination to become debt-free.</p>
                <p>No matter what size your debt is, there&rsquo;s hope!</p>
                <p>It is possible to pay off large debts quickly.</p>
                <p>You just have to make a game plan, and then stick to it religiously.</p>
                <p>Part of the plan should include using borrowing tools available to pay off the debt more quickly and efficiently at a lower cost.</p>
                <p>I know it&rsquo;s possible. I&rsquo;ve seen it happen.</p>
                <p>We&rsquo;re outlining the ways Jim Davies paid off $400,000 in 21 months.</p>
                <p>That way, you can take some of his strategies and apply them to paying off your own debt quickly.</p>
                <p>
                    <LazyLoadImage alt="2017 Type of Debt Owned by Americans" src="/media/2017-type-of-debt-owned-by-americans-1.png" />
                </p>
                <h2 id="create-a-payoff-plan">Create a Payoff Plan</h2>
                <p>The first step Jim Davies took to start attacking his debt involved identifying exactly what he owed.</p>
                <p>Then he did some math to figure out what it would take to get rid of the debt in less than two years.</p>
                <h3>Get a handle on your total debt</h3>
                <p>Almost two years ago, Jim Davies realized he was $400,000 in debt.</p>
                <p>He&rsquo;d worked hard to get the best education possible so he could become a doctor.</p>
                <p>Even though Jim was an expert in medicine, he had never learned about finances.</p>
                <p>He just kept borrowing when he needed to and didn&rsquo;t even think about paying back until after his education was complete.</p>
                <p>
                    <strong>14 years of school and training costs a lot of money.</strong>
                    {' '}
                    Jim had five years of undergraduate studies, five years of medical school, and four years of residency under his belt.
                </p>
                <p>He was proud to finally set himself up in his medical practice.</p>
                <p>It was now time for him to look closely at the debt he&rsquo;d accumulated over those 14 years so he could start tackling it.</p>
                <p>
                    <strong>Shocking debt totals scared him.</strong>
                    {' '}
                    Even Jim was surprised when he tallied up his
                    {' '}
                    <a href="https://www.debtconsolidation.com/student-loans/">student loan debt</a>
                    {' '}
                    and credit card bills.
                </p>
                <p>His debt-to-income ratio (i.e., what he owed versus what he earned) was 138%, or 100% higher than what lenders warn is the most anyone should have.</p>
                <p>
                    Jim owed $300,000 in various
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=jim-davies-debt&amp;sub2=student-loans" rel="noopener noreferrer" target="_blank">student loans</a>
                    {' '}
                    he had taken out over the years to finance his studies.
                </p>
                <p>
                    The average interest he was being charged for these federal and private
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=jim-davies-debt&amp;sub2=student-loans" rel="noopener noreferrer" target="_blank">student loans</a>
                    {' '}
                    was 6.8%.
                </p>
                <p>
                    He and his wife used
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=jim-davies-debt&amp;sub2=credit-cards" rel="noopener noreferrer" target="_blank">credit cards</a>
                    {' '}
                    to make ends meet when the loans weren&rsquo;t enough, so his credit card debt was up to $100,000.
                </p>
                <p>The APR he was paying on the credit card debt was 15.99%.</p>
                <p>It was costing him over $1,300 a month in interest alone.</p>
                <p>If he only kept up on the $4,000 monthly minimum payments, it was going to take just under 20 years to pay back.</p>
                <p>And, it would cost an additional $50,000 in interest.</p>
                <p>
                    <strong>He had good income and assets.</strong>
                    {' '}
                    As a practicing doctor, one thing Jim Davies did have going for him was a really good salary.
                </p>
                <p>Jim also had a high credit score.</p>
                <p>He&rsquo;d finally made it through medical school and residency, and now it was time to start reaping the financial rewards.</p>
                <p>His base salary was $250,000 a year. After taxes, he had an annual available income of $175,000.</p>
                <p>On top of that, his wife netted $40,000 a year at her job.</p>
                <p>
                    <strong>There&rsquo;s money in your house.</strong>
                    {' '}
                    Jim had the foresight when he started college to buy a condo rather than rent student accommodation.
                </p>
                <p>His parents had gifted him the down payment as his high school graduation present.</p>
                <p>Over the fourteen years of his education, he and his wife had been able to keep up with mortgage payments, and the condo was completely paid off.</p>
                <p>Although he&rsquo;d originally purchased it for $200,000, the condo had a value of $300,000 today.</p>
                <p>He also knew enough to keep up on all monthly minimum payments on his debts.</p>
                <p>His credit score was in good shape.</p>
                <p>
                    <strong>They faced a big choice over how long to take to pay back.</strong>
                    {' '}
                    Now they were aware of their total debt and total income, Jim and his wife had an important choice to make:
                </p>
                <p>
                    <strong>Should they pay the debt off over time?</strong>
                    {' '}
                    They could spread the debt repayment out over time—10 years? 20 years? 30 years?— and live the lifestyle they wanted right away.
                </p>
                <p>Small monthly payments spread out over a long period of time would hardly be noticeable.</p>
                <p>Even though it would be easier on their cash flow, Jim and his wife knew they&rsquo;d be paying many tens of thousands of dollars more in interest over the long term with this option.</p>
                <p>
                    <strong>Attack the debt with everything right now.</strong>
                    {' '}
                    The other option was to try to do whatever they could to erase the debt as soon as possible.
                </p>
                <p>They&rsquo;d live the rest of their life free from the student loan and credit card debt.</p>
                <p>They&rsquo;d also save tens of thousands of dollars in interest.</p>
                <h3>Ruthless budgeting is required</h3>
                <p>To achieve the goal of being debt-free quickly, Jim and his wife agreed to make debt repayment their #1 priority.</p>
                <p>They also agreed to tighten their belts on their expenses.</p>
                <p>The next step was to figure out the bare minimum they&rsquo;d need to live every month while they paid the debt down.</p>
                <p>
                    <strong>Sacrifice is necessary.</strong>
                    {' '}
                    Household budget spending decisions included tough cost-cutting choices.
                </p>
                <p>
                    <strong>No big purchases.</strong>
                    {' '}
                    Although it was tempting to splurge on a new car or home with Jim&rsquo;s new income, all big-ticket expenses were put on hold until the debt was cleared.
                </p>
                <p>
                    <strong>Look for bargains.</strong>
                    {' '}
                    The couple also agreed to look for deals on whatever they absolutely needed.
                </p>
                <p>Buying items on Craigslist and shopping for everything else at Walmart were two strategies they came up with.</p>
                <p>
                    <LazyLoadImage alt="U.S. Most Popular Mobile Shopping Apps in 2017" src="/media/u-s-most-popular-mobile-shopping-apps-in-2017-2.png" />
                </p>
                <p>
                    <strong>No vacations.</strong>
                    {' '}
                    Jim and his wife deserved a vacation after all the hard work they&rsquo;d put in getting him through med school and residency.
                </p>
                <p>They agreed to put any vacation plans on the back shelf until the debt was gone.</p>
                <p>
                    <strong>Living a humble life to become debt-free.</strong>
                    {' '}
                    After all the cuts and sacrifices were subtracted, the couple determined it would be possible for them to live on $40,000 a year.
                </p>
                <p>Since $40K was exactly what Jim&rsquo;s wife earned in a year, they agreed her salary would pay for all living expenses while Jim tackled the debt.</p>
                <h2 id="refinancing-and-consolidation">Refinancing and Consolidation</h2>
                <p>The next step Jim took was to look at the debts he had accumulated and decide on the best strategy for dealing with them.</p>
                <p>With multiple student loan payments and several maxed-out credit cards, he did the research on the options available.</p>
                <h3>Student loan refinancing made it possible</h3>
                <p>With the student loans he had taken out over the years, and interest accumulating the whole time, Jim owed an eye-popping $300,000.</p>
                <p>The various federal and private student loans were charging him an average interest rate of 6.8%.</p>
                <p>
                    <strong>Massive annual interest payments.</strong>
                    {' '}
                    Even at the reasonable interest rate of 6.8%, Jim would be paying $20,400 just in interest on that debt over a year.
                </p>
                <p>
                    <strong>Student loan refinancing created massive interest savings.</strong>
                    {' '}
                    Jim discovered there were some lending companies out there willing to
                    {' '}
                    <a href="https://www.debtconsolidation.com/student-loans/">refinance federal and private student loan debt</a>
                    {' '}
                    at a much lower rate of interest.
                </p>
                <p>He was certainly glad he had made the right moves to keep his credit score high.</p>
                <p>
                    <strong>No fees mean a faster pay off.</strong>
                    {' '}
                    He found an online lender to refinance his student loans with no extra fees.
                </p>
                <p>
                    <strong>Never pay to apply.</strong>
                    {' '}
                    In his research, Jim had learned to never go with a lender charging an application fee.
                </p>
                <p>He also learned loan origination fees could charge anywhere from 1–5%.</p>
                <p>With his $300,000 debt, the loan origination fee could be as high as $15,000.</p>
                <p>The online lender he chose didn&rsquo;t charge a loan origination fee at all.</p>
                <p>
                    <strong>Don&rsquo;t pay a penalty for paying off quickly.</strong>
                    {' '}
                    Jim also knew his plan was to pay off the loan as soon as he could, so he made sure there was no prepayment fee charged on his new refinancing loan.
                </p>
                <p>
                    <strong>Choosing &quot;variable&quot; can mean lower interest.</strong>
                    {' '}
                    One choice he found himself facing when he was making his application was whether to get a loan with a &quot;variable&quot; or &quot;fixed&quot; interest rate.
                </p>
                <p>With variable, your loan interest rate can change if bank interest rates change.</p>
                <p>With fixed, it will always stay the same.</p>
                <p>By choosing the variable rate option, he was taking a bit of a risk.</p>
                <p>If rates went up he&rsquo;d get hit hard.</p>
                <p>Since he knew he was going to pay the debt off very quickly, the risk wasn&rsquo;t as great.</p>
                <p>There wouldn&rsquo;t be a lot of time for the markets and rates to change.</p>
                <p>The variable rate was significantly lower than the fixed rate, so he went for it.</p>
                <p>
                    <strong>Low-interest student loan refinancing saved thousands.</strong>
                    {' '}
                    Jim ended up getting approved for a $300,000 refinancing loan at a 1.9% variable interest rate.
                </p>
                <p>Instead of paying $20,400 a year in interest, he&rsquo;d only be paying $5,700 in interest payments on his student loan debt.</p>
                <p><em>That&rsquo;s a saving of $14,700!</em></p>
                <h3>Balance transfer was an option for credit card debt</h3>
                <p>One tool Jim Davies discovered for dealing with credit card debt more efficiently was a balance transfer credit card.</p>
                <p>
                    He found some options for taking his existing high-interest credit card debts and moving them over to another
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=jim-davies-debt&amp;sub2=credit-card" rel="noopener noreferrer" target="_blank">credit card</a>
                    .
                </p>
                <p>
                    <strong>No interest or fees in year one.</strong>
                    {' '}
                    The advantages with the new card included a 12-month introductory period with 0% APR, plus no fee for making the transfers.
                </p>
                <p>When the 12-month intro period was over, the APR should be less than the 15.99% he was currently being charged due to his high credit score.</p>
                <p>
                    <strong>Not adequate for the size of his debt.</strong>
                    {' '}
                    Although the balance transfer option was an improvement on his current debt situation, he knew he wouldn&rsquo;t get approval on a credit card to cover the $100,000 total of his debts.
                </p>
                <h3>A home equity loan paid off the credit card balances</h3>
                <p>When he was doing his research, Jim discovered he was literally sitting on an asset he could leverage to get his credit card debt consolidated and paid off more quickly and with less interest.</p>
                <p>
                    <strong>Home equity loans let you borrow from your house investment.</strong>
                    {' '}
                    Jim and his wife had always been diligent about making mortgage payments on the condo over the years.
                </p>
                <p>
                    <LazyLoadImage alt="Americans Who Plan to Buy a House or Condominium from 2013-2017" src="/media/americans-who-plan-to-buy-a-house-or-condominium-from-2013-2017-3.png" />
                </p>
                <p>They&rsquo;d finally paid it off in full and were sitting on a $300,000 asset.</p>
                <p>Jim looked around for the best home equity loan he could find.</p>
                <p>Since his house was security for the loan, it was easy to get approved and he was able to shop around for the lowest interest rate.</p>
                <p>He ended up getting approved for a $100,000 home equity loan on the condo at 3% interest.</p>
                <p>All the credit cards were paid off immediately, and all Jim had to worry about was paying off the home equity loan.</p>
                <p>Instead of paying $11,250 a year in interest, he&rsquo;d only be charged at a rate of $3,000 per year.</p>
                <p>He saved over $8,000 in interest payments.</p>
                <p>
                    <strong>Lower interest was key to Jim&rsquo;s strategy.</strong>
                    {' '}
                    By refinancing his student loan and paying off his high-interest credit cards with a home equity loan,
                    {' '}
                    <em>Jim had saved himself a combined total of $22,950 a year in interest charges.</em>
                </p>
                <h2 id="more-income">More Income</h2>
                <p>Jim had successfully chosen two debt solutions to reduce his interest payments and make it possible to pay down the $400,000 total debt more quickly.</p>
                <p>If he was going to pay it off in less than two years, he was going to need more income.</p>
                <p>
                    <strong>$175K per year was a good start.</strong>
                    {' '}
                    The $175,000 Jim would earn every year through his medical practice could be put 100% to the debt.
                </p>
                <p>$14,500 was therefore available every month to pay off the home equity loan and the refinanced student loans.</p>
                <p>
                    <strong>They were still $5,500 short each month.</strong>
                    {' '}
                    When he did the math, he calculated he&rsquo;d need to come up with a monthly payment of $20,000 to eliminate the full debt in 21 months.
                </p>
                <h3>Turn your home into a rental property to generate income</h3>
                <p>Jim and his wife made the tough choice to move out of their condo for two years and rent it out.</p>
                <p>After condo fees were paid, they would have an extra $2,500 every month in rent to put towards the debt.</p>
                <p>
                    <strong>Reduce cost of living by changing neighborhoods.</strong>
                    {' '}
                    The decision to rent their condo also worked well with their other
                    {' '}
                    <a href="https://www.debtconsolidation.com/how-to-get-out-of-debt/">strategy to reduce their cost of living</a>
                    .
                </p>
                <p>They used Jim&rsquo;s wife&rsquo;s income to rent a cheap apartment in an area of the city where the cost for everything was lower.</p>
                <p>
                    <strong>Short-term rentals could be more lucrative.</strong>
                    {' '}
                    Jim had also looked into listing the condo on a short-term rental site like Airbnb.
                </p>
                <p>They could have generated more income by renting the condo for shorter periods and charging more money per day.</p>
                <p>It was a lot more work to manage different tenants all the time, so they chose to offer the condo as a two-year lease to one renter.</p>
                <h3>Do whatever it takes with side gigs</h3>
                <p>Jim didn&rsquo;t practice medicine on weekends.</p>
                <p>To him, it meant two more days to for him and his wife to earn more income.</p>
                <p>Over the 21 months, they averaged $500 a week ($2,000 a month) in extra income taking on whatever work they could possibly find.</p>
                <p>His wife babysat children on Saturday nights when their parents went out.</p>
                <p>They both took on odd jobs like painting houses and mowing lawns.</p>
                <p>Jim discovered online surveys where participants were paid for completing them.</p>
                <p>No job was too small, and every little bit helped.</p>
                <p>The couple worked their hands to the bone, fueled by the huge motivation to be debt-free in under two years.</p>
                <h3>Sell off unneeded stuff for extra revenue</h3>
                <p>When it came time to move from the condo to the cheaper apartment, the Davies had the chance to look at everything they owned and choose whether to keep it or sell it.</p>
                <p>
                    <strong>Bring the joy or get sold.</strong>
                    {' '}
                    They agreed if something didn&rsquo;t make them feel joy, it would go.
                </p>
                <p>The couple sold everything from purses to sports equipment to furniture.</p>
                <p>Facebook, Craigslist, and eBay made it easy for them to sell dozens and dozens of items.</p>
                <p>
                    <strong>Reselling works too.</strong>
                    {' '}
                    In fact, they got so good at selling their own stuff, they started buying other sellers&rsquo; items at bargain prices and reselling them for a profit.
                </p>
                <p>Their little side hustle generated an average of about $500 a month over the 21-month push to become debt-free.</p>
                <p>
                    <strong>Big ticket assets needed to go.</strong>
                    {' '}
                    Jim calculated they were only $500 a month shy of the $20,000 monthly payment goal they needed to clear his $400,000 debt in just 21 months.
                </p>
                <p>He knew it was time to bite the bullet.</p>
                <p>Jim and his wife both drove their own car. He offered to sell his vehicle and bike to work.</p>
                <p>They became a one-car household, and the final $500 a month they needed to make the $20,000 in monthly payments came through.</p>
                <h2 id="keep-yourself-motivated">Keep Yourself Motivated</h2>
                <p>With all of the sacrifices and hard work it was going to take to get debt-free in less than two years, Jim and his wife needed some strategies to keep themselves motivated.</p>
                <h3>Check in and celebrate progress</h3>
                <p>Every Sunday, Mr. and Mrs. Davies sat down together and looked at the balances on the home equity loan and the refinanced student loan.</p>
                <p>The balances shrunk fairly slowly at first as the monthly installments paid off the interest.</p>
                <p>Over time, they noticed the principle balance started to fall steadily.</p>
                <p>As their debt got smaller and smaller, their monthly $20,000 debt attack took bigger and bigger chunks off the total.</p>
                <h3>Make plans for what you&rsquo;ll do post-debt</h3>
                <p>By far, the couple&rsquo;s favorite pastime was to make plans for what they&rsquo;d do when the $400,000 debt was behind them.</p>
                <p>Mowing lawns and caring for little kids on Saturday nights was easier when they knew it was all in support of a much better life in less than two years.</p>
                <p>They discussed taking a dream vacation every year.</p>
                <p>
                    <LazyLoadImage alt="Data Showing Barriers Preventing Travelers From Visiting Their Dream Destination Worldwide" src="/media/data-showing-barriers-preventing-travelers-from-visiting-their-dream-destination-worldwide-4.png" />
                </p>
                <p>Of course, Jim dreamed of getting a new car to replace the one he had sold off.</p>
                <p>The new vehicle wouldn&rsquo;t be a sports car. It would be a minivan.</p>
                <p>Their plan was to start having children as soon as the 21 months of hard work and sacrifice were over, and the $400,000 debt was a thing of the past.</p>
                <p>Today, the family is completely debt-free and expecting twins.</p>
                <h3>If Jim Davies could pay off $400,000 in 21 months, you can get rid of your debt quickly too</h3>
                <p>The thing I like best about Jim Davies&rsquo; approach to eliminating a massive debt in less than two years is he didn&rsquo;t try to do it alone.</p>
                <p>For a lot of people, the idea of dealing with a giant debt by taking on new debt seems scary.</p>
                <p>
                    I have vast experience with debt consolidation and
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&amp;sub1=jim-davies-debt&amp;sub2=personal-loans" rel="noopener noreferrer" target="_blank">personal loans</a>
                    , and I can tell you they are among the best tools for reducing and eliminating debt.
                </p>
                <p>Without the home equity loan and the student loan refinancing Jim Davies was approved for, he would have never achieved his debt-free goals so quickly.</p>
                <p>He would have been stuck paying over twenty thousand extra dollars every year just in interest alone.</p>
                <p>Besides making the right choices for borrowing, the other key to the Davies&rsquo; success was hard work, commitment, and a win-at-all-costs attitude to become debt-free.</p>
                <p>Jim Davies overcame a $400,000 debt in just 21 months.</p>
                <h3>You can apply the lessons we learned from Jim to your own debt situation</h3>
                <p>The Davies had $400,000 in debt and $290,000 a year in income.</p>
                <p>His debt-to-income ratio was 138%, which is 100% higher than recommended.</p>
                <p>If you have a debt of $100,000, you could have it paid off in 21 months using the same techniques if you have a household income of $72,500 (the same debt-to-income ratio as Jim and his wife).</p>
                <p>Here are the most important takeaways from Jim&rsquo;s story that will get you paying off your debts as fast as possible:</p>
                <p>1) If you have a big student loan debt with high-interest payments, look around for lenders who can refinance your student loans.</p>
                <p>Make sure you choose a lender with no application fee, loan origination fee, or prepayment penalty.</p>
                <p>2) Choose a variable interest rate for the lowest borrowing cost, but only if you plan to pay off your debt quickly.</p>
                <p>3) If you&rsquo;re dealing with balances on multiple high-interest credit cards, consider a balance transfer credit card with a 12-month 0% APR intro offer and no balance transfer fee.</p>
                <p>4) To consolidate large amounts of debt within a single big loan with a low APR, shop around for a home equity loan.</p>
                <p>I&rsquo;m the kind of person who is driven to succeed.</p>
                <p>When you have a positive mental attitude, there&rsquo;s practically no debt that&rsquo;s impossible to overcome.</p>
                <p>With the right lending supports and borrowing tools, you can get whatever you need to make a debt go away faster and at much lower cost than doing it yourself.</p>
                <p>Do you have experience paying off a large debt in a short amount of time?</p>
                <p>What worked and what didn&rsquo;t?</p>
                <p>Any great tips (or nightmares) to share with the rest of us?</p>
                <p>Let us know in the comments below.</p>
            </>
        ),
    };

    return (
        <Post article={article} />
    );
};

export default JimDaviesDebtPage;
